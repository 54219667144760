<template>
    <div>
        <playbook-preview-panel
            v-model="playbookPreviewPanelOpen"
            :playbook="playbookToPreview" />
        <div>
            <h2>
                Landing Page Urls
            </h2>
            <p>
                Enter your desired landing page urls or leave them blank to use dealer website url by default.
            </p>
            <div class="lpurls">
                <div class="lpurls__left">
                    <div class="lpurls__switcher-holder">
                        <div class="lpurls__title">
                            Do you want to use a single URL or different URLs for each ad?
                        </div>
                        <div class="lpurls__selector">
                            <div
                                class="lpurls__select"
                                :class="{active: singleSelected}"
                                @click="chooseSingle">
                                Single URL for all ads
                            </div>
                            <div
                                class="lpurls__select"
                                :class="{active: !singleSelected}"
                                @click="chooseMultiple">
                                Different URLs for each ad
                            </div>
                        </div>
                    </div>
                    <v-form v-if="!singleSelected">
                        <v-layout
                            v-for="(adCreative, index) in adCreatives"
                            :key="adCreative.remote_name"
                            row>
                            <v-flex xs12>
                                <v-text-field
                                    :ref="'field'+index"
                                    :value="adCreative.landing_page_url"
                                    :label="adCreative.ad_name"
                                    class="py-3 my-0 styled-field"
                                    :rules="urlRules"
                                    light
                                    @input="updateLandingPageUrl($event, index)"
                                    @focus="activeLandingPageUrlIndex = index" />
                            </v-flex>
                        </v-layout>
                    </v-form>
                    <div v-else>
                        <v-text-field
                            ref="singleField"
                            v-model="singleURL"
                            label="LANDING PAGE URL"
                            :rules="urlRules"
                            class="py-3 my-0 styled-field"
                            light
                            @input="setSingleURL($event)" />
                    </div>
                </div>
                <div class="lpurls__right">
                    <button
                        v-if="playbookToPreview.marketing_assets.has_preview"
                        class="lpurls__button"
                        @click="onPlaybookPreview">
                        VIEW AD PREVIEWS
                    </button>
                </div>
            </div>
        </div>
        <div class="layout align-center">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                :disabled="notValid"
                @click="finishStep">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import PlaybookPreviewPanel from '@/components/playbooks/PlaybookPreviewPanel';
import BackButton from '@/components/globals/BackButton';
import StyledButton from '@/components/globals/StyledButton';
import { mapState } from 'vuex';
import colors from '@/helpers/colors';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';
import { get } from 'lodash';
import { urlRules } from '@/helpers/validationRules';

export default {
    name: 'OnboardingPlayLandingPageUrls',
    components: {
        BackButton,
        StyledButton,
        PlaybookPreviewPanel,
    },
    data() {
        return {
            adCreatives: [],
            activeLandingPageUrlIndex: 0,
            colors,
            playbookPreviewPanelOpen: false,
            singleURL: '',
            singleSelected: true,
            playbookToPreview: {
                marketing_assets: {
                    has_preview: false
                }
            },
            playbooksRaw: [],
            urlRules,
            notValid: false
        };
    },
    computed: {
        ...mapState({
            play: (state) => state.onboarding.play,
            currentDealer: (state) => state.dealer.currentDealer,
            playAdCreatives: (state) => state.onboarding.playAdCreatives,
            onboardingData: (state) => state.onboarding.data,
        })
    },
    watch: {
        adCreatives: {
            deep: true,
            handler(adCreatives) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, { adCreatives });
            }
        }
    },
    created() {
        // If there's existing data and it has the right number of campaigns use it
        const existingAdCreatives = get(this.onboardingData, 'adCreatives', null) || [];
        if (existingAdCreatives.length === this.playAdCreatives.length) {
            this.adCreatives = existingAdCreatives;
        }

        // Otherwise copy over the ad sets & prime the value with the minimum
        else {
            this.adCreatives = this.playAdCreatives?.map(adCreative => {
                return adCreative;
            });
        }
    },
    mounted() {
        this.singleURL = this.currentDealer.site_base_url;
        this.setSingleURL(this.singleURL);
        this.getPlaybooks();
    },
    methods: {
        finishStep() {
            if (this.singleSelected) {
                this.setSingleURL(this.singleURL);
            }
            this.$store.dispatch('goToNextStep');
        },
        updateLandingPageUrl(value, index) {
            const isValid = this.$refs['field'+index][0].validate();
            if (isValid) {
                this.notValid = false;
                // Ensure a state update occurs
                const newAdCreatives = [ ...this.adCreatives ];
                newAdCreatives[index].landing_page_url = value;
                this.adCreatives = newAdCreatives;
            } else {
                this.notValid = true;
            }
        },
        setSingleURL(value) {
            const isValid = this.$refs.singleField.validate();
            if (isValid) {
                this.notValid = false;
                const newAdCreatives = [ ...this.adCreatives ];
                newAdCreatives.forEach(creative =>{
                    creative.landing_page_url = value;
                });
                this.adCreatives = newAdCreatives;
            }  else {
                this.notValid = true;
            }

        },
        onPlaybookPreview() {
            this.playbookPreviewPanelOpen = true;
        },
        async getPlaybooks() {
            try {
                const response = await this.$apiRepository.getPlaybooks();
                this.playbooksRaw = response.data.data;
                const currentPlaybook = this.playbooksRaw.find(playbook => playbook.plays?.[0]?.id == this.play.id);
                this.playbookToPreview = currentPlaybook;
            } catch (error) {
                console.error('Error loading playbooks', error);
            }
        },
        chooseSingle() {
            this.singleSelected = true;
            this.$nextTick(function() {
                const isValid = this.$refs.singleField.validate();
                if (isValid) {
                    this.notValid = false;
                }  else {
                    this.singleURL = '';
                }
            });
        },
        chooseMultiple() {
            this.singleSelected = false;
            this.notValid = false;
        },
    }
};
</script>

<style lang="scss" scoped>
.lpurls{
    padding: 30px;
    background: #F7F8FC;
    display: flex;
    width: 100%;
    margin-bottom: 30px;
}
.lpurls__right{
    width: 30%;
    text-align: right;
}
.lpurls__left{
    width: 70%;
    padding-right: 10%;
}
.lpurls__button{
    padding: 1px 20px;
    background: white;
    border: 2px solid #00A4EB;
    border-radius: 5px;
    display: inline-block;
    color: #00A4EB;
    font-size: 16px;
    font-weight: 600;
}
.lpurls__title{
    font-weight: 600;
}
.lpurls__selector{
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
}
.lpurls__select{
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
    &:after{
        content:'';
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 50%;
        border: 2px solid #555C63;
        top: 0;
        right: calc(100% + 10px);
    }
    &.active{
        &:after{
            border: 2px solid #00A3EA;
        }
        &:before{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: #00A3EA;
            border-radius: 50%;
            top: 5px;
            right: calc(100% + 15px);
            z-index: 2;
        }
    }
}
</style>
